/* records  */
.Records {
  background-color: #ffffff;
  color: #474283;
  font-family: "Lucida Sans Typewriter", "Lucida Console", monaco,
    "Bitstream Vera Sans Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  user-select: none;
}

.Records .card {
  background-color: transparent;
  border: transparent;
  user-select: none;
}

.Records .img {
  display: block;
  user-select: none;
}

.Records .img--grid {
  position: relative;
  max-width: 100%;
  box-shadow: 2px 16px 26px 0px rgba(36, 33, 69, 0.3);
  z-index: 4;
  user-select: none;
}

.Records .lp {
  position: absolute;
  z-index: 2;
  user-select: none;
}

.Records .lp--grid {
  left: 40px;
  width: 100%;
  height: 100%;
  transition: left 0.4s;
  user-select: none;
}

/* image wrap and deco lp */

.Records .img-wrap {
  position: relative;
  width: 18rem;
  text-align: left;
  user-select: none;
}

.Records .albums-larg .img-wrap {
  position: relative;
  width: 35rem;
  text-align: left;
  user-select: none;
}

.Records .img-wrap:hover .lp--grid {
  left: 70px;
}

.Records .albums-larg .artist {
  font-size: 1em;
  font-weight: normal;
  margin: 1em 0 0.5em;
  user-select: none;
  color: #474283;
}

.Records .artist {
  font-size: 0.85em;
  font-weight: normal;
  margin: 1em 0 0.5em;
  user-select: none;
  color: #474283;
}

.Records .albums-larg .title {
  font-size: 2em;
  font-weight: bold;
  margin: 0 0 0 3em;
  white-space: wrap;
  user-select: none;
  color: #474283;
}

.Records .title {
  font-size: 1em;
  font-weight: bold;
  margin: 0 0 0 3em;
  white-space: wrap;
  user-select: none;
  color: #474283;
}

.Records .albums-larg .year {
  font-size: 1.5em;
  position: relative;
  z-index: 4;
  top: -555px;
  right: -33rem;
  margin: -1em -1em 0 0;
  padding: 0.3em 0.5em;
  color: #fff;
  border: 2px solid;
  background-color: #474283;
  user-select: none;
}

.Records .year {
  font-size: 0.85em;
  position: absolute;
  z-index: 4;
  top: -10px;
  right: 0;
  margin: -1em -1em 0 0;
  padding: 0.3em 0.5em;
  color: white;
  border: 2px solid;
  background-color: #474283;
  user-select: none;
}

.Records .index {
  color: #cccadc38;
  font-size: 360px;
  left: -50px;
  bottom: 100px;
  position: absolute;
  z-index: 0;
  user-select: none;
}

/* Styles for large screens */
@media (min-width: 1100px) {
  .Records .albums-larg .index {
    color: #cccadc38;
    font-size: 500px;
    left: -290px;
    top: 330px;
    position: absolute;
    z-index: 0;
    user-select: none;
  }
}

.Records .filter {
  color: #474283;
  text-decoration: none;
  transition: 0.4s;
}

.Records .filter:hover {
  color: #d4d3e3;
}

/* Styles for small screens (up to 767px) */
@media only screen and (max-width: 767px) {
  .Records .albums-larg .img-wrap {
    position: relative;
    width: 15rem;
    text-align: left;
    top: 100px;
  }

  .Records .albums-larg .title {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .close-button {
    left: 10px;
  }
}
