.master {
  position: fixed;
  width: 100%;
  height: 100vh;
}

.header .navbar {
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.sidenav {
  height: 100vh;
  width: 100%;
  padding: 10px;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 20px;
  border-color: transparent;
}

.sidenav a {
  color: #012970;
}

.sidenav a:hover {
  color: #4154f1;
}

.sidenav .list-group {
  width: 100%;
  border-radius: 0;
  padding-right: 10px;
}
.sidenav .list-group-item .icon {
  font-size: 15px;
  top: 0;
  margin: 5px;
}

.sidenav .list-group-item .text {
  font-size: 15px;
  top: 0;
  padding-top: 10px;
}

.sidenav .list-group .active {
  background-color: #f6f9ff;
  border-color: transparent;
  color: #4154f1;
}

.sidenav .list-group-item {
  border-color: transparent;
  padding: 10px;
  margin: 10px;
  line-height: 30px;
}

#sidenav {
  display: block;
}

@media only screen and (max-width: 800px) {
  #sidenav {
    display: none;
    position: fixed;
    width: 100vw;
    z-index: 1;
    padding: 0;
  }
}

.master #main {
  background-color: #f6f9ff;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
}

#breadcrum a {
  text-decoration: none;
  color: #818181;
  font-size: 14px;
}

#breadcrum li {
  text-decoration: none;
  font-size: 14px;
}

/* Metrics Card */
.metrics-card .card {
  border-color: transparent;
  box-shadow: 0px 3px 15px rgba(1, 42, 112, 0.044);
}

.muted {
  color: #899bbd;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.metrics-card .card-icon,
.card-icon-2,
.card-icon-3 {
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.metrics-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.metrics-card .card-icon-2 {
  color: #ff771d;
  background: #ffecdf;
}

.metrics-card .card-icon-3 {
  color: #2eca6a;
  background: #e0f8e9;
}
.rounded-circle {
  border-radius: 50% !important;
}

.align-items-center {
  align-items: center !important;
}

.metrics-card h6 {
  font-size: 27px;
  font-weight: 600;
  bottom: 0;
}

.metrics-card .small {
  line-height: 0;
  font-size: 12px;
}

/* Content Card */
.content-card .card {
  border-color: transparent;
  box-shadow: 0px 3px 15px rgba(1, 42, 112, 0.044);
}
